import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { cacheKey } from './constants'
import { MetabaseReport } from './models'

export type ReportResponse = {
  reports: MetabaseReport[]
}

const selectorAll = (data: ReportResponse): ReportResponse => {
  return data
}

export const useAllMetabaseReports = (
  organizationUid: string | undefined,
): UseQueryResult<ReportResponse, ResponseError> =>
  useQuery({
    queryKey: [cacheKey, 'analytics'],
    queryFn: () =>
      request.get<ReportResponse>('metabase/reports/list?api-version=3.3'),
    select: selectorAll,
    enabled: !!organizationUid,
  })
